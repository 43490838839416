import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// FontAwesome icon sets
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowRightToBracket,
  faBan,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown as fasChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp as fasChevronUp,
  faCircle,
  faCircleCheck,
  faDash,
  faDownLeftAndUpRightToCenter,
  faEllipsis,
  faFileLines,
  faHome,
  faInfo,
  faLinkSimple,
  faMagnifyingGlass,
  faRectangleVertical,
  faSearch,
  faSlidersUp,
  faSort,
  faSortDown,
  faSortUp,
  faSquare as fasSquare,
  faTimes,
  faTrashCan,
  faUpRightAndDownLeftFromCenter,
  faUserGroup,
  faXmarkLarge,
  faImage,
  faCirclePlus,
  faPencil,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons"; // fas
import {
  faArrowDown,
  faArrowDownArrowUp,
  faArrowDownToLine,
  faArrowLeft,
  faArrowRight,
  faArrowRotateRight,
  faArrowUp,
  faArrowUpWideShort,
  faBarsProgress,
  faBullhorn,
  faCalendarDays,
  faCalendarRange,
  faChartBar,
  faChartColumn,
  faChartLine,
  faChartPie,
  faChartScatterBubble,
  faChevronDown,
  faChevronUp,
  faCircleExclamation,
  faCircleMinus,
  faComputer,
  faEnvelope,
  faEquals,
  faEyeSlash,
  faFileChartColumn,
  faFrown,
  faGlobe,
  faGripLines,
  faInfoCircle,
  faKeySkeleton,
  faList,
  faMeh,
  faMinusCircle,
  faNewspaper,
  faObjectExclude,
  faPen as farPen,
  faPenToSquare,
  faPlus,
  faRadio,
  faShapes,
  faSmile,
  faSquare as farSquare,
  faTrashCan as farTrashCan,
  faTv,
  faTvRetro,
  faUnlockKeyhole,
  faLockKeyhole,
  faXmark,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
} from "@fortawesome/pro-regular-svg-icons"; // far
import {
  faAngleLeft as falAngleLeft,
  faAngleRight as falAngleRight,
  faBookUser,
  faCircleQuestion,
  faCog,
  faFileChartLine,
  faPen,
  faSignOut,
  faSliders,
  faTimesCircle,
  faUserChart,
  faPenToSquare as falPenToSquare,
  faTrashCan as falTrashCan,
} from "@fortawesome/pro-light-svg-icons"; // fal
import { faTwitter, faXTwitter } from "@fortawesome/free-brands-svg-icons";

// Disable auto CSS addition by Nuxt
config.autoAddCss = false;

// Add all icons to the library
library.add(
  // Light icons (fal)
  falAngleLeft,
  falAngleRight,
  falPenToSquare,
  falTrashCan,
  faBookUser,
  faCircleQuestion,
  faCog,
  faFileChartLine,
  faPen,
  faSignOut,
  faSliders,
  faTimesCircle,
  faUserChart,

  // Solid icons (fas)
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowRightToBracket,
  faBan,
  faCaretDown,
  faCaretUp,
  faCheck,
  fasChevronDown,
  faChevronLeft,
  faChevronRight,
  fasChevronUp,
  faCircle,
  faCircleCheck,
  faDash,
  faDownLeftAndUpRightToCenter,
  faEllipsis,
  faFileLines,
  faHome,
  faInfo,
  faLinkSimple,
  faMagnifyingGlass,
  faRectangleVertical,
  faSearch,
  faSlidersUp,
  faSort,
  faSortDown,
  faSortUp,
  fasSquare,
  faTimes,
  faTrashCan,
  faUpRightAndDownLeftFromCenter,
  faUserGroup,
  faXmarkLarge,
  faImage,
  faCirclePlus,
  faPencil,
  faTrash,

  // Regular icons (far)
  faArrowDown,
  faArrowDownArrowUp,
  faArrowDownToLine,
  faArrowLeft,
  faArrowRight,
  faArrowRotateRight,
  faArrowUp,
  faArrowUpWideShort,
  faBarsProgress,
  faBullhorn,
  faCalendarDays,
  faCalendarRange,
  faChartBar,
  faChartColumn,
  faChartLine,
  faChartPie,
  faChartScatterBubble,
  faChevronDown,
  faChevronUp,
  faCircleExclamation,
  faCircleMinus,
  faComputer,
  faEnvelope,
  faEquals,
  faEyeSlash,
  faFileChartColumn,
  faFrown,
  faGlobe,
  faGripLines,
  faInfoCircle,
  faKeySkeleton,
  faList,
  faMeh,
  faMinusCircle,
  faNewspaper,
  faObjectExclude,
  farPen,
  faPenToSquare,
  faPlus,
  faRadio,
  faShapes,
  faSmile,
  farSquare,
  farTrashCan,
  faTv,
  faTvRetro,
  faUnlockKeyhole,
  faLockKeyhole,
  faXmark,
  farArrowLeft,
  farArrowRight,

  // Brand icons
  faTwitter,
  faXTwitter,
);

// Register FontAwesome component globally
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon);
});
